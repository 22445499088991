<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  created() {},
  mounted() {},
  watch: {
    $route(to) {
      this.setBreadcrumb(to)
    }
  },
  methods: {
    /**
     * 设置面包屑
     */
    setBreadcrumb(to) {
      // console.log("设置面包屑", to, to.matched);
      this.$store.commit(
        'SET_BREADCRUMB',
        to.matched.map((item) => {
          return {
            title: item.meta.title,
            name: item.name,
            path: item.path || '/'
          }
        })
      )
    }
  }
}
</script>
<style lang="scss">
.el-loading-mask {
  position: fixed !important;
}
</style>
