<template>
  <svg :class="className" xmlns="http://www.w3.org/2000/svg">
    <title v-if="name">{{ name }}</title>
    <use :xlink:href="iconPath" xmlns:xlink="http://www.w3.org/1999/xlink" />
  </svg>
</template>

<script>
export default {
  name: "svg-icon",
  props: {
    name: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      default: null,
    },
  },
  computed: {
    iconPath() {
      console.log("name====", this.name);
      let icon = require(`@/assets/icons/svg/${this.name}.svg`);
      console.log("icon", icon);
      if (Object.prototype.hasOwnProperty.call(icon, "default")) {
        icon = icon.default;
      }
      return icon.url;
    },
    className() {
      return "svg-icon svg-icon--" + this.name;
    },
  },
};
</script>

<style>
.svg-icon {
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}
</style>
