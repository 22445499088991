<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="admin">
    <el-container class="height-percent-100">
      <el-aside :width="!isCollapse ? '220px' : '65px'" class="aside scrollbar">
        <div
          class="logo"
          v-if="showLogo"
          @click="
            () => {
              $router.push({ path: '/' });
            }
          "
        >
          <span v-if="!isCollapse">XX管理平台</span>
          <el-image style="width: 30px" v-else :src="logo"></el-image>
        </div>
        <div v-else class="logo"></div>
        <el-scrollbar style="height: calc(100vh - 60px)">
          <el-menu
            :default-active="$route.path"
            :collapse="isCollapse"
            class="menu"
            background-color="#304156"
            text-color="#fff"
            router
            :collapse-transition="false"
            active-text-color="#1990FF"
          >
            <template v-for="(item, index) in menuList">
              <el-submenu
                v-if="item.childList.length"
                :index="item.url"
                :key="index"
              >
                <template slot="title">
                  <Item :icon="item.icon" :title="item.name" />
                </template>
                <el-menu-item-group>
                  <el-menu-item
                    v-for="(_item, _index) in item.childList"
                    :key="_index"
                    :index="_item.url"
                  >
                    <template slot="title">
                      <Item :icon="_item.icon" :title="_item.name" />
                    </template>
                  </el-menu-item>
                </el-menu-item-group>
              </el-submenu>
              <el-menu-item v-else :index="item.url" :key="index">
                <Item :icon="item.icon" :title="item.name" />
              </el-menu-item>
            </template>
          </el-menu>
        </el-scrollbar>
      </el-aside>
      <el-container>
        <el-header class="display-flex ai-center jc-space-between header">
          <el-icon :size="20" @click="isCollapse = !isCollapse">
            <Expand v-if="isCollapse" />
            <Fold v-else />
          </el-icon>
          <!-- <i class="cursor-pointer fs-20" @click="isCollapse = !isCollapse" :class="isCollapse ? 'el-icon-s-unfold' : 'el-icon-s-fold'"></i> -->
          <div class="user">
            <el-dropdown trigger="click" @command="handleCommand">
              <div class="display-flex ai-center">
                <el-avatar :size="30" :src="info && info?.avatar"></el-avatar>
                <span class="el-dropdown-link cursor-pointer ml-14 fs-14">
                  {{ info && info?.nick_name
                  }}<el-icon class="el-icon--right"><arrow-down /></el-icon>
                </span>
              </div>
              <template #dropdown>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="home">首页</el-dropdown-item>
                  <el-dropdown-item command="loginOut"
                    >退出登录</el-dropdown-item
                  >
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </div>
        </el-header>
        <el-main class="pt-16 scrollbar">
          <el-breadcrumb separator-class="el-icon-arrow-right" class="mb-16">
            <el-breadcrumb-item
              v-for="(item, index) in breadcrumb"
              :to="item.path"
              :key="index"
            >
              <span
                class="cursor-pointer"
                :style="{
                  color:
                    breadcrumb.length - 1 === index ? '#1990FF' : '#999999',
                }"
                >{{ item.title }}</span
              >
            </el-breadcrumb-item>
          </el-breadcrumb>
          <div class="scrollbar bc-white br-5">
            <el-scrollbar style="height: calc(100vh - 130px)">
              <router-view :key="$route.fullPath"></router-view>
            </el-scrollbar>
          </div>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import Item from "@/components/Item";
export default {
  mame: "Admin",
  components: { Item },
  data() {
    return {
      loading: this.openLoading(),
      isCollapse: false,
      showLogo: true,
    };
  },
  computed: {
    avatar() {
      return this.store.state.user.info.avatarUrl;
    },
    info() {
      return {
        nick_name: "Admin",
        avatarUrl: "",
      };
    },
    breadcrumb() {
      return this.$store.state.breadcrumb;
    },
    menuList() {
      const menu = [
        {
          url: "/admin/dashboard",
          icon: "dashboard",
          name: "控制台",
          childList: [],
        },
        {
          url: "/admin/order-management",
          icon: "skill",
          name: "订单管理",
          childList: [
            {
              url: "/admin/order-management",
              icon: "tree-table",
              name: "订单管理",
            },
            {
              url: "/admin/enterprise-list",
              icon: "peoples",
              name: "企业列表",
            },
          ],
        },
        {
          url: "/admin/system",
          icon: "system",
          name: "系统管理",
          childList: [
            {
              url: "/admin/system/menu-management",
              icon: "tree-table",
              name: "菜单管理",
            },
            {
              url: "/admin/system/user-management",
              icon: "peoples",
              name: "用户管理",
            },
          ],
        },
      ];
      return menu;
    },
  },
  watch: {},
  methods: {
    handleCommand(command) {
      if (command === "home") {
        this.$router.push({ path: "/" });
      } else if (command === "loginOut") {
        this.$confirm("你确认要退出登录吗, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            window.location.href = "/auth_login";
            setTimeout(() => {
              this.$store
                .dispatch(
                  "SetCachedViews",
                  this.cachedViews.filter((item) => item === "Home")
                )
                .then(() => {
                  this.$store.commit("SET_USER_INFO", {});
                  this.$store.commit("SET_TOKEN", "");
                  this.$cookies.remove("token");
                });
            }, 500);
          })
          .catch(() => {});
      }
    },
  },
  created() {
    this.loading.close();
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
.aside {
  overflow: hidden;
  transition: width 0.25s;

  .logo {
    color: white;
    background-color: #021f3c;
    height: 60px;
    font-size: 18px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }

  .menu {
    width: 100%;
    height: calc(100vh - 60px);
    border: none;

    &.collapse {
      margin-left: 0px;
    }

    :deep() {
      .el-menu-item-group__title {
        // color: var(--system-menu-text-color);
        display: none;
      }

      .el-menu-item.is-active {
        background-color: rgb(38, 52, 69) !important;
      }
    }
  }
}

header {
  padding: 0;
  background-color: #fff;

  > i {
    padding: 20px;

    &:hover {
      background-color: rgb(244, 244, 244);
    }
  }

  .user {
    padding: 20px;
  }
}

.el-main {
  height: calc(100vh - 220px) !important;
}
</style>
