import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    breadcrumb: [], // 面包屑
  },
  getters: {
  },
  mutations: {
    SET_BREADCRUMB (state, data) {
      this.state.breadcrumb = data
    },
  },
  actions: {
  },
  modules: {
  }
})
