<template>
  <div class="title-decoration-1">
    <div class="decoration_title">{{ title }}</div>
    <div class="deciratio_center">
      <slot></slot>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  props: {
    title: {
      type: String,
      default: () => "",
    },
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>
<style lang="scss" scoped>
.title-decoration-1 {
  // border:1px solid red;
  .decoration_title {
    height: 49px;
    font-size: 18px;
    font-weight: 600;
    color: #e2eef2;
    line-height: 49px;
    padding-left: 45px;
    color: #e2eef2;
    background: url("~@/assets/images/decoration-bg/decoration_bg_1.png");
    background-position: left top, right bottom, left top;
    background-repeat: no-repeat, no-repeat, repeat-x;
    background-size: auto;
  }
  .deciratio_center {
    height: calc(100% - 70px);
    padding-top: 10px;
    background-color: #081842;
  }
}
</style>
