<template>
  <div class="title-deciratio-2">
    <div
      class="decoration_title display-flex jd-row jc-space-between ai-center"
    >
      <div class="display-flex jd-row jc-space-between ai-center">
        <div class="mybkkd"></div>
        <span>{{ title }}</span>
      </div>
      <slot name="button"></slot>
    </div>
    <div
      style="
        width: 18px;
        height: 4px;
        background-color: #fff;
        float: right;
        margin-top: -1px;
      "
    ></div>
    <div class="deciratio_center">
      <slot></slot>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  props: {
    title: {
      type: String,
      default: () => "",
    },
    seeMore: {
      type: Function,
      default: () => "Default function",
    },
  },
  created() {},

  mounted() {},
  methods: {},
};
</script>
<style lang="scss" scoped>
.title-deciratio-2 {
  // .decoration_title{
  //   height: 50px;
  //   font-size: 16px;
  //   font-weight: 600;
  //   color: #E2EEF2;
  //   // line-height: 15px;
  //   padding-left: 35px;
  //   color: #E2EEF2;
  //   background: url('~@/assets/images/decoration-bg/decoration_bg_2.png');
  //   background-position: right bottom, left top;
  //   background-repeat: no-repeat, no-repeat, repeat-x;
  //   background-size: contain;
  //   // border:1px solid red
  // }
  .decoration_title {
    padding: 10px;
    border-bottom: 1px solid #00366f;
    span {
      color: #a3c3e5;
      font-size: 16px;
      font-weight: 600;
    }
    .mybkkd {
      width: 14px;
      height: 14px;
      margin-right: 10px;
      background-color: #fff;
      transform: rotate(45deg);
    }
  }
  .deciratio_center {
    height: calc(100% - 70px);
    padding-top: 10px;
    // background-color: #081842;
  }
}
</style>
