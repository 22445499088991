<template>
  <div class="title-decoration-3">
    <!--  display-flex jd-row jc-space-between ai-center -->
    <div class="decoration_title display-flex ai-flex-start">
      <span>{{ title }}</span>
    </div>
    <div class="deciratio_center" :style="centerStyle">
      <slot></slot>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  props: {
    title: {
      type: String,
      default: () => "",
    },
    centerStyle: {
      type: Object,
      default: () => {},
    },
  },
  created() {},

  mounted() {},
  methods: {},
};
</script>
<style lang="scss" scoped>
.title-decoration-3 {
  width: 100%;
  // border: 1px solid blue;
  .decoration_title {
    width: 100%;
    height: 40px;
    font-weight: 600;
    color: #e2eef2;
    // padding-left: 20px;
    color: #e2eef2;
    background: url("~@/assets/images/decoration-bg/decoration_bg_3.png");
    background-position: right bottom, left top;
    background-repeat: no-repeat, no-repeat, repeat-x;
    background-size: 100% 100%;
    // border: 1px solid blue;
    span {
      text-align: center;
      padding-left: 20px;
      font-size: 22px;
      background-image: linear-gradient(#fff, #52ebf5);
      -webkit-background-clip: text;
      color: transparent;
    }
  }
  .deciratio_center {
    margin-top: 20px;
    // background-color: #081842;
    // border: 1px solid #52ebf5;
  }
}
</style>
