<template>
  <div
    class="reacquire-page display-flex jc-center"
    :style="{ lineHeight: lineHeight }"
    @click="getData"
  >
    <span> 重新获取 </span>
  </div>
</template>

<script>
export default {
  name: "reacquire-page",
  components: {},
  props: {
    lineHeight: {
      type: String,
      default: "200px",
    },
  },
  data() {
    return {};
  },
  computed: {},
  methods: {
    init() {},
    getData(e) {
      this.$emit("onclick", e);
    },
  },
  created() {},
  mounted() {},
  beforeDestroy() {},
};
</script>

<style lang="scss" scoped>
.reacquire-page {
  user-select: none;
  color: rgb(168, 168, 168);
  span:hover {
    color: #1890ff;
    text-decoration: underline;
  }
}

.blocks {
  width: 100%;
  height: 100%;
}

.cursor-pointer {
  cursor: pointer;
}
</style>
