<template>
  <div class="embox-border-1">
    <div class="embox-border_label">
      {{ label }}
    </div>
    <div class="embox-border_value" :style="valueStyle">
      {{ value }}
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  props: {
    label: {
      type: String,
      default: () => "",
    },
    value: {
      type: String,
      default: () => "",
    },
    valueStyle: {
      type: Object,
      default: () => ({ color: "#fff" }),
      // default: () => {}
    },
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>
<style lang="scss" scoped>
.embox-border-1 {
  padding: 15px;
  background: url("~@/assets/images/embox-border/embox-border_bg_1.png");
  background-position: left top, right bottom, left top;
  background-repeat: no-repeat, no-repeat, repeat-x;
  background-size: 100% 100%;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .embox-border_label {
    font-size: 16px;
    color: #a5afc2;
  }
  .embox-border_value {
    font-size: 32px;
    font-weight: bold;
  }
}
</style>
