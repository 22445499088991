import Vue from 'vue'
import "@/assets/style/common.scss"
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import ElementUI from "element-ui"
import "element-ui/lib/theme-chalk/index.css"

import Moment from 'moment'
import 'moment/locale/zh-cn'

// import VideoPlayer from 'vue-video-player'
// import 'video.js/dist/video-js.css'
// import 'vue-video-player/src/custom-theme.css'
// import videojs from 'video.js'

// 将自动注册所有组件为全局组件
import dataV from '@jiaminghi/data-view'
import vueMiniPlayer from 'vue-mini-player'
import 'vue-mini-player/lib/vue-mini-player.css'
import BaiduMap from 'vue-baidu-map-v3'

// 引入组件
import ScaleScreen from '@/components/ScaleScreen/index.vue'
import Echart from './components/echart/index.vue'
import Reacquire from './components/reacquire/reacquire.vue'
import titleDecoration1 from './components/title-decoration/title-decoration-1.vue'
import titleDecoration2 from './components/title-decoration/title-decoration-2.vue'
import titleDecoration3 from './components/title-decoration/title-decoration-3.vue'
import emboxBorder1 from './components/embox-border/embox-border-1.vue'
import SvgIcon from '@/components/SvgIcon.vue'

Vue.use(vueMiniPlayer)
Vue.use(ElementUI)
Vue.use(dataV)
Vue.use(BaiduMap, {
  // ak 是在百度地图开发者平台申请的密钥 详见 http://lbsyun.baidu.com/apiconsole/key */
  ak: 'zW0VXkOQTeB34CISl2DGNPCHoaCHejF2'
})

Vue.config.productionTip = false

Vue.prototype.$moment = Moment

Vue.prototype.openLoading = function () {
  const loading = this.$loading({
    // 声明一个loading对象
    lock: true, // 是否锁屏
    text: "加载中", // 加载动画的文字
    spinner: "el-icon-loading", // 引入的loading图标
    background: "hsla(0,0%,100%,.9)", // 背景颜色
    target: ".el-table, .table-flex, .region", // **需要遮罩的区域，这里写要添加loading的选择器**
    fullscreen: false,
    customClass: "loadingclass", // **遮罩层新增类名，如果需要修改loading的样式**
  })
  setTimeout(function () {
    // 设定定时器，超时5S后自动关闭遮罩层，避免请求失败时，遮罩层一直存在的问题
    loading.close() // 关闭遮罩层
  }, 5000)
  return loading
}


Vue.use(BaiduMap, {
  // ak 是在百度地图开发者平台申请的密钥 详见 http://lbsyun.baidu.com/apiconsole/key */
  ak: 'zW0VXkOQTeB34CISl2DGNPCHoaCHejF2'
})

// 全局注册icon-svg
Vue.component('SvgIcon', SvgIcon)

Vue.component('ScaleScreen', ScaleScreen)
// eslint-disable-next-line vue/multi-word-component-names
Vue.component('Echart', Echart)
// eslint-disable-next-line vue/multi-word-component-names
Vue.component('Reacquire', Reacquire)
Vue.component('title-decoration-1', titleDecoration1)
Vue.component('title-decoration-2', titleDecoration2)
Vue.component('title-decoration-3', titleDecoration3)
Vue.component('embox-border-1', emboxBorder1)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
