import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/views/admin/Layout/Index.vue'
Vue.use(VueRouter)
// 添加这下面一段代码，就可以解决重复点击路由报错
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch((err) => err)
}

const routes = [
  // 前台
  {
    path: '/',
    name: 'Home',
    redirect: '/index',
    meta: { title: '首页' },
    component: () => import('@/views/home/Index.vue'),
    // children: [
    //   {
    //     path: 'index',
    //     name: 'index',
    //     meta: { title: '首页' },
    //     component: () => import('@/views/home/HomePage.vue')
    //   },
    //   {
    //     path: 'about',
    //     name: 'About',
    //     meta: { title: '关于我' },
    //     component: () => import('@/views/home/About')
    //   },
    //   {
    //     path: 'pdf',
    //     name: 'Pdf',
    //     meta: { title: 'pdf' },
    //     redirect: '/pdf/vuepdf',
    //     component: () => import('@/views/home/Player/Index.vue'),
    //     children: [
    //       {
    //         path: 'vuepdf',
    //         name: 'vuepdf',
    //         meta: {
    //           title: 'pdf预览',
    //           breadcrumb: ['pdf', 'pdf预览'],
    //         },
    //         component: () => import('@/views/home/Pdf/vuepdf/index.vue')
    //       },
    //       {
    //         path: 'html2canvas',
    //         name: 'html2canvas',
    //         meta: {
    //           title: 'pdf下载',
    //           breadcrumb: ['pdf', 'pdf下载'],
    //         },
    //         component: () => import('@/views/home/Pdf/html2canvas/index.vue')
    //       },
    //     ]
    //   },
    //   {
    //     path: 'player',
    //     name: 'Player',
    //     redirect: '/player/xgplayer',
    //     meta: {
    //       title: '视频播放器',
    //
    //     },
    //     component: () => import('@/views/home/Player/Index.vue'),
    //     children: [
    //       {
    //         path: 'xgplayer',
    //         name: 'XgPlayer',
    //         meta: {
    //           title: '西瓜播放器',
    //           breadcrumb: ['播放器插件', '西瓜播放器'],
    //         },
    //         component: () => import('@/views/home/Player/xgplayer/index.vue')
    //       },
    //       {
    //         path: 'vue-video-player',
    //         name: 'VueVideoPlayer',
    //         meta: {
    //           title: 'VueVideoPlayer',
    //           breadcrumb: ['播放器插件', 'VueVideoPlayer'],
    //         },
    //         component: () => import('@/views/home/Player/vue-video-player/index.vue')
    //       },
    //       {
    //         path: 'dplayer',
    //         name: 'DPlayer',
    //         meta: {
    //           title: 'DPlayer',
    //           breadcrumb: ['播放器插件', 'DPlayer'],
    //         },
    //         component: () => import('@/views/home/Player/DPlayer/index.vue'),
    //       },
    //     ]
    //   }
    // ]
  },
  {
    path: '/screen',
    name: 'Screen',
    redirect: '/screen/iot',
    meta: {
      title: '大屏',
    },
    component: () => import('@/views/home/Screen/Index.vue'),
    children: [
      {
        path: 'iot',
        name: 'iot',
        meta: {
          title: '物联网平台',
          breadcrumb: ['数据可视化', '物联网平台'],
        },
        component: () => import('@/views/home/Screen/iot/index.vue')
      },
      {
        path: 'dust',
        name: 'dust',
        meta: {
          title: '粉尘监控'
        },
        component: () => import('@/views/home/Screen/dust/index')
      },
      {
        path: 'antiknock',
        name: 'antiknock',
        meta: {
          title: '抗爆柜'
        },
        component: () => import('@/views/home/Screen/antiknock/index')
      }
    ]
  },
  // 后台
  {
    path: '/admin',
    name: 'Admin',
    meta: { title: '后台管理' },
    redirect: "/admin/dashboard",
    component: Layout,
    children: [
      {
        path: "dashboard",
        name: "Dashboard",
        meta: { title: "控制台" },
        component: () => import("@/views/admin/Dashboard.vue"),
      },
      {
        path: 'order-management',
        name: 'orderManagement',
        meta: {
          title: '订单管理',
          breadcrumb: ['培训管理', '订单管理']
        },
        component: () => import('@/views/admin/trainPlat/orderManagement/List.vue')
      },
      {
        path: 'enterprise-list',
        name: 'enterpriseList',
        meta: {
          title: '企业列表',
          breadcrumb: ['企业管理', '企业列表']
        },
        component: () => import('@/views/admin/enterprise/List.vue')
      },
      {
        path: 'system/user-management',
        name: 'userManagement',
        meta: {
          title: '用户管理',
          breadcrumb: ['系统管理', '用户管理']
        },
        component: () => import('@/views/admin/system/userManagement/index.vue')
      },
      {
        path: 'system/menu-management',
        name: 'menuManagement',
        meta: {
          title: '菜单管理',
          breadcrumb: ['系统管理', '菜单管理']
        },
        component: () => import('@/views/admin/system/menuManagement/index.vue')
      }
    ]
  },
  // 登录
  {
    path: '/auth_login',
    name: 'Login',
    meta: { title: '登录' },
    component: () => import('@/views/auth/Login.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  // 修改title
  // window.document.title = to.meta.title + '- 管理平台'
  if(to.fullPath=== '/index'){
  next('/screen/iot')
  }
  console.log('to====', to)
  next()
})

export default router
